<template>
  <v-app>
    <ErrorAlert />
    <SuccessAlert />
    <router-view />
  </v-app>
</template>
<script>
import ErrorAlert from "@/components/main/alerts/ErrorAlert";
import SuccessAlert from "@/components/main/alerts/SuccessAlert";

export default {
  components: {
    ErrorAlert,
    SuccessAlert,
  },
  data: () => ({
    success: false,
  }),
  methods: {},
  created() {},
  mounted() {
    if (window.PANEL_HTML_PAGE_TITLE) {
      document.title = window.PANEL_HTML_PAGE_TITLE;
    }
  },
};
</script>
<style lang="scss">
@import "styles/fonts.scss";
@import "styles/vuetifyEditStyles.scss";
@import "styles/main.scss";
@import "styles/button.scss";
@import "styles/colors.scss";

* {
  font-family: "Pragmatica";
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  overscroll-behavior: none;
}

html {
  overflow: auto !important;
}

body {
  a {
    // text-decoration: none;
    color: var(--app-ros-blue-1);
  }
}
::-webkit-scrollbar {
  width: 0;
}
</style>
