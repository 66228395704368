import { render, staticRenderFns } from "./ErrorAlert.vue?vue&type=template&id=16a96f80&scoped=true&"
import script from "./ErrorAlert.vue?vue&type=script&lang=js&"
export * from "./ErrorAlert.vue?vue&type=script&lang=js&"
import style0 from "./ErrorAlert.vue?vue&type=style&index=0&id=16a96f80&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16a96f80",
  null
  
)

export default component.exports