import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

// import store from "../store/index";
const routes = [
  {
    path: "/",
    name: "LayoutMain",
    component: () => import("../views/main/Layout.vue"),
    children: [
      {
        path: "/main",
        name: "MainPage",
        component: () => import("../views/main/MainPage.vue"),
      },
      {
        path: "/info",
        name: "Info",
        component: () => import("../views/main/Info.vue"),
      },

      {
        path: "/projects",
        name: "Projects",
        component: () => import("../views/main/projects/Projects.vue"),
      },
      {
        path: "/projects/:id",
        name: "Project",
        component: () => import("../views/main/projects/Project.vue"),
      },
      {
        path: "/projectsgreat",
        name: "ProjectsGreat",
        component: () => import("../views/main/projectsGreat/Projects.vue"),
      },
      {
        path: "/projectsgreat/:id",
        name: "ProjectGreat",
        component: () => import("../views/main/projectsGreat/Project.vue"),
      },
      {
        path: "/task",
        name: "Task",
        component: () => import("../views/main/task/Task.vue"),
      },
    ]
  },
  // {
  //   path: "/partner/:lang/form",
  //   name: "PartnerForm",
  //   component: () => import("../views/main/partners/PartnerForm.vue"),
  // },
  // {
  //   path: "/chat/:lang/form",
  //   name: "ChatForm",
  //   component: () => import("../views/main/chat/ChatForm.vue"),
  // },
  // {
  //   path: "/session/:lang/form",
  //   name: "SessionForm",
  //   component: () => import("../views/main/session/SessionForm.vue"),
  // },

  // {
  //   path: "/livetape",
  //   name: "ChatLine",
  //   component: () => import("../views/main/chat/ChatLine.vue"),
  // },
  // {
  //   path: '/YWRtaW5QYW5lbFJvc01vbG9kZXpo',
  //   name: 'Login',
  //   component: () => import("../views/Login.vue"),
  //   async beforeEnter(to, from, next) {
  //     if (await store.dispatch('authorizationAdminPanel')) {
  //       next({ name: 'LayoutAP' })
  //     } else {
  //       next()
  //     }
  //   },
  // },
  // {
  //   path: '/YWRtaW5QYW5lbFJvc01vbG9kZXpo/adminPanel',
  //   name: 'LayoutAP',
  //   component: () => import("../views/ap/LayoutAP.vue"),
  //   async beforeEnter(to, from, next) {

  //     if (await store.dispatch('authorizationAdminPanel')) {
  //       next()
  //     } else {
  //       next({ name: 'Login' })
  //     }
  //   },
  //   children: [

  //     {
  //       path: 'questionnaires',
  //       name: 'QuestionnairesAP',
  //       component: () => import("../views/ap/questionnaire/Questionnaires.vue"),
  //       async beforeEnter(to, from, next) {
  //         if (await store.dispatch('authorizationAdminPanel')) {
  //           next()
  //         } else {
  //           next({ name: 'Login' })
  //         }
  //       },

  //     },

  //     {
  //       path: 'schedule',
  //       name: 'Schedule',
  //       component: () => import("../views/ap/schedule/Schedule.vue"),
  //       async beforeEnter(to, from, next) {
  //         if (await store.dispatch('authorizationAdminPanel')) {
  //           next()
  //         } else {
  //           next({ name: 'Login' })
  //         }
  //       },

  //     },
  //     {
  //       path: 'chat',
  //       name: 'ChatList',
  //       component: () => import("../views/ap/chat/ChatList.vue"),
  //       async beforeEnter(to, from, next) {
  //         if (await store.dispatch('authorizationAdminPanel')) {
  //           next()
  //         } else {
  //           next({ name: 'Login' })
  //         }
  //       },

  //     },
  //     {
  //       path: 'polls',
  //       name: 'PollsQuestions',
  //       component: () => import("../views/ap/polls/PollsQuestions.vue"),
  //       async beforeEnter(to, from, next) {
  //         if (await store.dispatch('authorizationAdminPanel')) {
  //           next()
  //         } else {
  //           next({ name: 'Login' })
  //         }
  //       },

  //     },
  //     {
  //       path: 'session',
  //       name: 'SessionAP',
  //       component: () => import("../views/ap/session/Session.vue"),
  //       async beforeEnter(to, from, next) {
  //         if (await store.dispatch('authorizationAdminPanel')) {
  //           next()
  //         } else {
  //           next({ name: 'Login' })
  //         }
  //       },

  //     },




  //   ]
  // }


];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
